import React from 'react';

import Icons from '@/core/components/Icons';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import { FFName, useFFContext } from '@/core/lib/ff/ff.context';
import type { DonationPersonae } from '@/core/lib/new-architecture/domain/donation.domain';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { DonationState } from '@/core/types/donation';

interface DonationCardStateProps {
  state: DonationState;
  full?: boolean;
  personae: DonationPersonae;
}

const DonationCardState: React.FC<DonationCardStateProps> = ({ state, full = false, personae }) => {
  const { t } = useTranslationContext(['common']);
  const isLg = useIsLg();
  const { ff } = useFFContext();

  const commonDiv = 'flex items-center gap-1 rounded-1 px-2 py-1';
  const commonText = 'text-caption-primary font-medium';

  switch (state) {
    case DonationState.INITIALIZED:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-moderation ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <p className={`${commonText} text-content-tag-status-moderation`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    case DonationState.MODERATION:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-moderation ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <p className={`${commonText} text-content-tag-status-moderation`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    case DonationState.RESENT:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-pendingMod ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <p className={`${commonText} text-content-tag-status-pendingMod`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    case DonationState.PUBLISHED:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-published ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <p className={`${commonText} text-content-tag-status-published`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    case DonationState.RESERVED: {
      const defaultBookedTag = (
        <div className={`${commonDiv} bg-bg-tag-status-booked ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <Icons icon="lock" size={isLg ? '16' : '12'} weight="bold" color="tag-booked" />
          <p className={`${commonText} text-content-tag-status-booked`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );
      if (ff(FFName.BookedDistinction)) {
        switch (personae) {
          case 'giver':
            return (
              <div className={`${commonDiv} bg-bg-tag-status-bookedGiver ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
                <Icons icon="lock" size={isLg ? '16' : '12'} weight="bold" color="tag-bookedDistinction" />
                <p className={`${commonText} text-content-tag-status-bookedDistinction`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
              </div>
            );
          default:
            return defaultBookedTag;
        }
      }
      return defaultBookedTag;
    }

    case DonationState.ENDED:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-finished ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <Icons icon="check-square" size={isLg ? '16' : '12'} weight="bold" color="tag-finished" />
          <p className={`${commonText} text-content-tag-status-finished`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    case DonationState.REFUSED:
      return (
        <div className={`${commonDiv} bg-bg-tag-status-refused ${full ? 'w-full justify-center' : 'max-w-fit'}`}>
          <Icons icon="block" size={isLg ? '16' : '12'} weight="bold" color="tag-refused" />
          <p className={`${commonText} text-content-tag-status-refused`}>{t(`components.donation.details.status.${state}`, { ns: 'common' })}</p>
        </div>
      );

    default:
      return null;
  }
};

export default DonationCardState;
