import React from 'react';

import DonationCardCity from '@/core/components/DonationCard/DonationCardCity';
import DonationCardDate from '@/core/components/DonationCard/DonationCardDate';
import DonationCardDistance from '@/core/components/DonationCard/DonationCardDistance';
import DonationCardPhoto from '@/core/components/DonationCard/DonationCardPhoto';
import DonationCardState from '@/core/components/DonationCard/DonationCardState';
import DonationCardTitle from '@/core/components/DonationCard/DonationCardTitle';
import type DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';

interface HDonationCardProps {
  donation: DonationDomain;
}

const HDonationCard: React.FC<HDonationCardProps> = ({ donation }) => {
  const { Link } = useRouterContext();

  const { personae } = donation;
  const id = donation.getId();
  const title = donation.getTitle();
  const city = donation.getCityLabel();
  const distance = donation.getDistance();
  const photo = donation.getFirstPhoto();
  const state = donation.getState();
  const lastPublishedAt = donation.getLastPublishedAt();

  return (
    <Link href={new Routes.DonationRoute(id)} className="flex w-full flex-row gap-3">
      <DonationCardPhoto photo={photo} title={title} height={128} width={118} />

      <div className="flex w-full flex-col items-start justify-center gap-2 px-3 py-2 lg:py-3">
        <DonationCardTitle title={title} />

        <div className="flex flex-col gap-1">
          <DonationCardState state={state} personae={personae} />

          <DonationCardCity city={city} />

          <div className="flex flex-row gap-4">
            <DonationCardDistance distance={distance} />

            <DonationCardDate date={lastPublishedAt} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HDonationCard;
