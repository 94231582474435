import 'react-image-gallery/styles/css/image-gallery.css';

import React, { useEffect, useRef, useState } from 'react';

import DonationCard from '@/core/components/DonationCard';
import useEventListener from '@/core/hooks/useEventListener';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import type CatalogueDomain from '@/core/lib/new-architecture/domain/catalogue.domain';
import { useRouterContext } from '@/core/lib/router/router.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface ArrowIconProps {
  onClick?: () => void | undefined;
  direction: 'right' | 'left';
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ direction, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className={`w-fit cursor-pointer ${direction === 'left' ? 'rotate-180' : ''}`} onClick={onClick} aria-label={direction}>
      <svg className="fill-[#FFFFFF] stroke-[#262641] hover:fill-[#262641] hover:stroke-[#FFFFFF]" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="30" height="30" rx="15" />
        <rect x="1" y="1" width="30" height="30" rx="15" stroke="#262641" strokeWidth="2" />
        <path d="M13.5 21L18.5 16L13.5 11" strokeWidth="2.08" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export interface CarouselDonationCardsProps {
  title?: string | null;
  donations: CatalogueDomain;
}

const CarouselDonationCards: React.FC<CarouselDonationCardsProps> = ({ donations, title }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const isLg = useIsLg();

  const { t } = useTranslationContext(['common']);
  const { Link } = useRouterContext();

  const mobileSettings = {
    cardWidth: 156,
    gap: 8,
  };

  const desktopSettings = {
    cardWidth: 186,
    gap: 36,
  };

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [scrollValue, setScrollValue] = useState<number>(0);
  const [scrollEnd, setScrollEnd] = useState<number>(0);

  const gapElement = isLg ? desktopSettings.gap : mobileSettings.gap;
  const cardWidth = isLg ? desktopSettings.cardWidth : mobileSettings.cardWidth;

  const isLastView: boolean | null = parentRef.current && currentIndex === donations.donations.length - Math.round(parentRef.current.clientWidth / (cardWidth + gapElement));

  useEffect(() => {
    // define maxScrollValue
    if (parentRef.current) {
      setScrollEnd(parentRef.current.scrollWidth);
    }

    // define current scroll position according currentIndex
    if (currentIndex === 0) {
      setScrollValue(0);
    }
    if (isLastView) {
      setScrollValue(scrollEnd);
    }
    if (!(currentIndex === 0) && !isLastView) {
      setScrollValue(currentIndex * cardWidth + gapElement * currentIndex);
    }
  }, [currentIndex, parentRef, isLastView]);

  useEffect(() => {
    // when the scrollvalue change, trigger the scroll
    if (parentRef.current) {
      parentRef.current.scrollTo({ left: scrollValue, behavior: 'smooth' });
    }
  }, [scrollValue]);

  // mobile view only : calculate the nearest index according scroll value
  const onScrollEnd = () => {
    if (parentRef.current) {
      const nearestIndex = Math.round(parentRef.current.scrollLeft / (cardWidth + gapElement));

      if (nearestIndex !== currentIndex) {
        setCurrentIndex(nearestIndex);
      }
    }
  };

  useEventListener('scrollend', onScrollEnd, parentRef);

  const handleNext = () => {
    if (donations.donations && currentIndex < donations.donations.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="relative flex flex-col gap-4 lg:gap-5">
      <div className="flex justify-between">
        {title && <p className="font-semibold text-content-primary">{title}</p>}
        <Link className="text-body-secondary font-medium text-content-secondary" href={donations.getRoute()}>
          {t('see-all', { ns: 'common' })}
        </Link>
      </div>
      <div ref={parentRef} className="hide-scrollbar grid auto-cols-[156px] grid-flow-col gap-2 overflow-x-auto overflow-y-hidden lg:auto-cols-[186px] lg:gap-9">
        {donations.donations.map(donation => (
          <DonationCard key={donation.getId()} donation={donation} vertical />
        ))}
      </div>
      <div className="z-1 absolute left-0 top-1/2 hidden lg:block">{currentIndex !== 0 && <ArrowIcon direction="left" onClick={handlePrevious} />}</div>
      <div className="z-1 absolute right-0 top-1/2 hidden lg:block">{!isLastView && <ArrowIcon direction="right" onClick={handleNext} />}</div>
    </div>
  );
};

export default CarouselDonationCards;
